<template>
	<div :class="themeClass" class="HelpCenter-Box">
	<div >
		<div class="HelpCenter-title">
			帮助中心
		</div>
		<div class="HelpCenter-con">
			<div>
				<i class="el-icon-shopping-cart-2"></i>
				<p class="HelpCenter-t">购物指南</p>
			</div>
			<div class="HelpCenter-two">
				<div class="HelpCenter-one">
					<p>购物流程</p>
					<p>平台资质</p>
					<p>优惠券的使用</p>
				</div>
				<div class="HelpCenter-three">
					<p>经营执照</p>
					<p>会员介绍</p>
					<p>价格说明</p>
				</div>
			</div>
		</div>
		
		<div class="HelpCenter-con">
			<div>
				<i class="el-icon-shopping-cart-2"></i>
				<p class="HelpCenter-t">配送服务</p>
			</div>
			<div class="HelpCenter-two">
				<div class="HelpCenter-one1">
					<p>配送服务收费标准</p>
					<p>配送服务收费标准</p>
				</div>
				<div class="HelpCenter-three">
					<p>商家配送时效声明</p>
					<p>商家配送时效声明</p>
				</div>
			</div>
		</div>
		
		<div class="HelpCenter-con">
			<div>
				<i class="el-icon-shopping-cart-2"></i>
				<p class="HelpCenter-t">支付方式</p>
			</div>
			<div class="HelpCenter-two">
				<div class="HelpCenter-one1">
					<p>货到付款</p>
					<p>发票说明</p>
				</div>
				<div class="HelpCenter-three">
					<p>在线支付</p>
				</div>
			</div>
		</div>
		
		<div class="HelpCenter-con">
			<div>
				<i class="el-icon-shopping-cart-2"></i>
				<p class="HelpCenter-t">常见问题</p>
			</div>
			<div class="HelpCenter-two">
				<div class="HelpCenter-news">
					<p>订单已提交成功，什么时候可以送到？</p>
					<p>我的商品是否可以退换货</p>
					<p>商品的规格是什么？</p>
					<p>订单已提交成功后还可以修改订单信息吗？</p>
				</div>
			</div>
		</div>
		
		<div class="HelpCenter-con">
			<div>
				<i class="el-icon-shopping-cart-2"></i>
				<p class="HelpCenter-t">常见问题</p>
			</div>
			<div class="HelpCenter-two">
				<div class="HelpCenter-news1">
					<p>取消订单后，款项如何返回？？</p>
					<p>订单已提交成功后，如何付款？</p>
					<p>订单已提交成功后，如何修改地址？？</p>
					<p></p>
					<!-- <p>取消订单后，款项如何返回？</p>
					<p>订单已提交成功后，如何付款？</p>
					<p>订单已提交成功后，如何修改地址？</p> -->
				</div>
			</div>
		</div>
		
		<div class="HelpCenter-con">
			<div>
				<i class="el-icon-shopping-cart-2"></i>
				<p class="HelpCenter-t">常见问题</p>
			</div>
			<div class="HelpCenter-two">
				<div class="HelpCenter-news">
					<p>订单已提交成功，什么时候可以送到？</p>
					<p>我的商品是否可以退换货</p>
					<p>商品的规格是什么？</p>
					<p>订单已提交成功后还可以修改订单信息吗？</p>
				</div>
			</div>
		</div>
	</div>
	
</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		}
</script>

<style scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	
	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}
	
	.border-color-theme {
		@include themify($themes) {
			border: 2rpx solid themed('themes_color');
		}
	}
	.HelpCenter-Box {
		width: 907px;
		/* height:100%; */
		height: calc(100% - 10px);
		background-color: #FFFFFF;
		float: right;
		padding-left: 10px;
		padding-top: 10px;
		margin-top:12px;
		
	}

	.HelpCenter-title {
		font-weight: bold;
		color: #000000;
		margin:0px 0px;
		font-size:18px;
		margin-bottom: 13px;
	}

	.HelpCenter-con {
		width:283px;
		/* width: 295px; */
		height: 170px;
		background: #f8f9fe;
		border: 3px solid #eaeaea;
		float:left;
		margin-right:13px;
		margin-bottom:13px;
	}

	.el-icon-shopping-cart-2 {
		width: 20px;
		height: 20px;
		/* background: #0173fe; */
		border: 1px solid #0173fe;
		font-weight: 400;
		color: #666666;
		border-radius: 50px;
		font-size: 13px;
		color: #0173fe;
		line-height: 20px;
		text-align: center;
		float: left;
		margin: 12px;
	}

	.HelpCenter-t {
		display: block;
		font-size: 14px;
		color: #000000;
		padding-top: 12px;
	}
	.HelpCenter-one{
		float:left;
		margin-left:-35px;
		margin-right:70px;
		margin-bottom: 20px;
	}
	.HelpCenter-one1{
		float:left;
		margin-right:18px;
		margin-left:-35px;
		}
	.HelpCenter-two{
		margin-top:10px;
	}
	.HelpCenter-two p{
		font-size: 13px;
		font-family: '微软雅黑 Regular', '微软雅黑', '微软雅黑 Regular', '微软雅黑'-400;
		font-weight: 400;
		color: #0173fe;
		line-height:26px;
	}
	.HelpCenter-news{
	    float:left;
		margin-left:12px;
	    margin-bottom: 20px;
	}
	.HelpCenter-news1{
		float:left;
		margin-left:-35px;
		margin-bottom: 20px;
		}
</style>
